/* Colores base */
:root {
  --color-primario: #e2984a; /* Naranja para acentos */
  --color-fondo: #f5f5f5; /* Gris claro para el fondo */
  --color-texto: #333; /* Gris oscuro para el texto */
}

body {
  background-color: var(--color-fondo);
  color: var(--color-texto);
  font-family: 'Open Sans', sans-serif;
}

.dashboard {
  padding: 20px;
  display: flex;
  flex-direction: column; /* Cambiado a columna para alinear verticalmente */
  align-items: center; /* Alineación central de los elementos */
}

.dashboard-header, .table-container, .map-container, #map {
  width: 100%; /* Ancho consistente para todos los elementos */
  box-sizing: border-box;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Espacio entre el encabezado y el siguiente elemento */
}

.dashboard-header h1 {
  margin: 0;
}

.dashboard-header .logout-button {
  padding: 10px 20px;
  background-color: var(--color-primario);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.table-container, .map-container {
  padding: 20px;
  border-radius: 10px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px; /* Espacio entre los contenedores */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #eee;
  padding: 15px;
  text-align: left;
}

th {
  background-color: var(--color-primario);
  color: white;
}

tr:nth-child(even) {
  background-color: #fafafa;
}

.checkbox-label {
  display: inline-block;
  margin-right: 10px;
}

.checkbox-label input[type="checkbox"] {
  margin-right: 5px;
}

/* Botones */
button {
  background-color: var(--color-primario);
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: white;
  color: var(--color-primario);
  border: 1px solid var(--color-primario);
}

#map {
  height: 300px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
